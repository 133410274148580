import React from 'react';
import PropTypes from 'prop-types';

import { parseClassNames } from '@utilities/parseClassNames';

const Column = ({ className, children }) => {
	const classNames = parseClassNames(['column', className]);
	return <div className={classNames}>{children}</div>;
};

Column.defaultProps = {
	className: '',
};

Column.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default Column;
