import React from 'react';
import { withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';

import useSiteMetadata from './SiteMetadata';

const MetaData = () => {
	const { title, description } = useSiteMetadata();
	return (
		<Helmet>
			<html lang="en" />
			<title>{title}</title>
			<meta name="description" content={description} />

			<link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}img/apple-touch-icon.png`} />
			<link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-32x32.png`} sizes="32x32" />
			<link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-16x16.png`} sizes="16x16" />

			<link rel="mask-icon" href={`${withPrefix('/')}img/safari-pinned-tab.svg`} color="#ff4400" />
			<meta name="theme-color" content="#fff" />

			<meta property="og:type" content="business.business" />
			<meta property="og:title" content={title} />
			<meta property="og:url" content="/" />
			<meta property="og:image" content={`${withPrefix('/')}img/og-image.jpg`} />
			<link
				crossOrigin="anonymous"
				href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
				integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
				rel="stylesheet"
			/>
		</Helmet>
	);
};

export default MetaData;
