import React from 'react';

import Container from '@atoms/Container/Container';
import Columns from '@atoms/Columns/Columns';
import Column from '@atoms/Column/Column';

import Facebook from '@img/social/facebook.svg';
import Instagram from '@img/social/instagram.svg';
import Twitter from '@img/social/twitter.svg';
import YouTube from '@img/social/youtube.svg';

import RogueRiverLogo from '@img/global/rogue-river-films-logo.jpg';
import APTNLogo from '@img/global/aptn-logo-sm.png';
import CMFLogo from '@img/global/CMF-logo.jpg';

import FooterStyles from './Footer.module.scss';

const Footer = () => (
	<Container>
		<footer className={FooterStyles.footer}>
			<Columns>
				<Column>
					<ul className={FooterStyles.nav}>
						<li>
							<a href="/">Yukon Harvest - Home</a>
						</li>
						<li>
							<a href="/mayo-community/">Mayo Community</a>
						</li>
						<li>
							<a href="/preserving-our-culture/">Preserving Our Culture</a>
						</li>
						<li>
							<a href="/behind-the-scenes/">Behind the Scenes</a>
						</li>
					</ul>
				</Column>
				<Column className="is-3">
					<ul className={FooterStyles.social}>
						<li>
							<a
								href="https://www.facebook.com/Yukon-Harvest-580676159087393"
								target="_blank"
								rel="noopener noreferrer">
								<img src={Facebook} alt="Facebook logo" />
							</a>
						</li>
						<li>
							<a
								href="https://www.youtube.com/channel/UChE8q1NjaATWNCUOWBjCW0Q"
								target="_blank"
								rel="noopener noreferrer">
								<img src={YouTube} alt="YouTube logo" />
							</a>
						</li>
						<li>
							<a href="https://twitter.com/HarvestYukon" target="_blank" rel="noopener noreferrer">
								<img src={Twitter} alt="Twitter logo" />
							</a>
						</li>
						<li>
							<a href="https://www.instagram.com/yukonharvest/" target="_blank" rel="noopener noreferrer">
								<img src={Instagram} alt="Instagram logo" />
							</a>
						</li>
					</ul>
				</Column>
				<Column className={FooterStyles.legal}>
					&copy; 2020 Yukon Harvest. All Rights Reserved.
					<Columns className={FooterStyles.associates}>
						<Column className="is-3">
							<img src={RogueRiverLogo} alt="Rogue River logo" />
						</Column>
						<Column className="is-3">
							<a href="https://www.aptn.ca/yukonharvest" target="_blank" rel="noopener noreferrer">
								<img src={APTNLogo} alt="APTN logo" />
							</a>
						</Column>
						<Column>
							<a href="https://www.cmf-fmc.ca/" target="_blank" rel="noopener noreferrer">
								<img src={CMFLogo} alt="CMF Fund logo" />
							</a>
						</Column>
					</Columns>
				</Column>
			</Columns>
		</footer>
	</Container>
);

export default Footer;
