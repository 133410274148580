import React from 'react';
import ReactPlayer from 'react-player';

import Container from '@atoms/Container/Container';
import CopyBlock from '@atoms/CopyBlock/CopyBlock';
import Header from '@molecules/Header/Header';
import Cta from '@atoms/Cta/Cta';

import HeroStyles from './Hero.module.scss';

const Hero = ({
	images,
	title,
	subTitle = '',
	video,
	videoTitle,
	videoDescription,
	videoDescriptionTrans = '',
	fullVideoUrl,
}) => {
	return (
		<>
			<picture className={HeroStyles.backgroundImage}>
				<source srcSet={images.xlarge} media="(min-width: 1200px)" />
				<source srcSet={images.desktop} media="(min-width: 768px)" />
				<img srcSet={images.tablet} alt="" />
			</picture>
			<Container className={HeroStyles.container}>
				<Header />
				<CopyBlock className={HeroStyles.copyBlock}>
					<h1 className="is-1" dangerouslySetInnerHTML={{ __html: title }} />
					{subTitle && <p dangerouslySetInnerHTML={{ __html: subTitle }} />}
				</CopyBlock>
				<div className={HeroStyles.videoContainer}>
					<div className={HeroStyles.videoWrapper}>
						<ReactPlayer
							url={video}
							vimeo={{ preload: true }}
							controls
							className={HeroStyles.videoPlayer}
							width="100%"
							height="100%"
						/>
					</div>
					<div className={HeroStyles.videoCopyBlock}>
						{videoTitle && <h3 className="is-2">{videoTitle}</h3>}
						<p className="copy highlight" dangerouslySetInnerHTML={{ __html: videoDescription }} />
						{videoDescriptionTrans && (
							<p className="copy italic highlight" dangerouslySetInnerHTML={{ __html: videoDescriptionTrans }} />
						)}
						{fullVideoUrl && (
							<Cta url={fullVideoUrl} theme="light">
								Watch Full Episode
							</Cta>
						)}
					</div>
				</div>
			</Container>
		</>
	);
};

export default Hero;
