import React from 'react';

import { parseClassNames } from '@utilities/parseClassNames';

const CopyBlock = ({ children, className = '' }) => {
	const classNames = parseClassNames([className, 'copy-block']);
	return <div className={classNames}>{children}</div>;
};

export default CopyBlock;
