import React from 'react';
import PropTypes from 'prop-types';

import { parseClassNames } from '@utilities/parseClassNames';

const Columns = ({ className, children }) => {
	const classNames = parseClassNames(['columns', className]);
	return <div className={classNames}>{children}</div>;
};

Columns.defaultProps = {
	className: '',
};

Columns.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default Columns;
