import React from 'react';

import { parseClassNames } from '@utilities/parseClassNames';

const Icon = ({ font = 'fas', name, className = '' }) => {
	const classNames = parseClassNames([font, className, name]);
	return (
		<span className="icon">
			<i className={classNames} />
		</span>
	);
};

export default Icon;
