import React from 'react';

import Heading from '@atoms/Heading/Heading';
import Container from '@atoms/Container/Container';
import ContactFormStyles from './ContactForm.module.scss';

const ContactForm = () => {
	return (
		<div className={ContactFormStyles.contact}>
			<Container>
				<Heading headingLevel="h2" size="2">
					Stay Up-to-date
				</Heading>
				<p>
					Contact Rogue River Films at <a href="mailto:info@rogueriverfilms.com">info@rogueriverfilms.com</a> or stay
					up-to-date by subscribing our mailing list.
				</p>
				<form name="signup" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
					<div hidden>
						<input id="bot-field" name="bot-field" />
					</div>
					<input type="hidden" name="form-name" value="signup" />
					<input type="text" name="email" placeholder="Email Address" />
					<input type="submit" value="Sign Me Up" />
				</form>
			</Container>
		</div>
	);
};

export default ContactForm;
