import React, { useRef, useState } from 'react';
import { Link } from 'gatsby';

import Cta from '@atoms/Cta/Cta';
import Icon from '@atoms/Icon/Icon';

import Logo from '@img/global/logo.png';
import APTNLogo from '@img/global/aptn-logo-sm.png';
import Facebook from '@img/social/facebook.svg';
import Instagram from '@img/social/instagram.svg';
import Twitter from '@img/social/twitter.svg';
import YouTube from '@img/social/youtube.svg';
import HeaderStyles from './Header.module.scss';

const Header = () => {
	const mobileNavRef = useRef();
	const [mobileHidden, setMobileHidden] = useState(true);
	return (
		<header className={HeaderStyles.header}>
			<nav className={HeaderStyles.navMobile}>
				<a href="/#">
					<img src={Logo} alt="" />
				</a>
				<div className={HeaderStyles.navContainer}>
					<Cta
						url="/#"
						theme="dark"
						className={HeaderStyles.mobileToggle}
						onAction={e => {
							e.preventDefault();
							setMobileHidden(!mobileHidden);
						}}>
						<Icon name="fa-bars" />
					</Cta>
					<ul aria-hidden={mobileHidden} className={HeaderStyles.mobileNavList} ref={mobileNavRef}>
						<li>
							<Link to="/" activeClassName="active">
								Home
								<span />
							</Link>
						</li>
						<li>
							<Link to="/mayo-community/" activeClassName="active">
								Mayo Community
								<span />
							</Link>
						</li>
						<li>
							<Link to="/behind-the-scenes/" activeClassName="active">
								Behind the Scenes
								<span />
							</Link>
						</li>
						<li>
							<Link to="/preserving-our-culture/" activeClassName="active">
								Preserving our Culture
								<span />
							</Link>
						</li>
						<li>
							<ul className={HeaderStyles.social}>
								<li>
									<a
										href="https://www.facebook.com/Yukon-Harvest-580676159087393"
										target="_blank"
										rel="noopener noreferrer">
										<img src={Facebook} alt="Facebook logo" />
									</a>
								</li>
								<li>
									<a
										href="https://www.youtube.com/channel/UChE8q1NjaATWNCUOWBjCW0Q"
										target="_blank"
										rel="noopener noreferrer">
										<img src={YouTube} alt="YouTube logo" />
									</a>
								</li>
								<li>
									<a href="https://twitter.com/HarvestYukon" target="_blank" rel="noopener noreferrer">
										<img src={Twitter} alt="Twitter logo" />
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/yukonharvest/" target="_blank" rel="noopener noreferrer">
										<img src={Instagram} alt="Instagram logo" />
									</a>
								</li>
								<li>
									<a href="https://www.aptn.ca/yukonharvest" target="_blank" rel="noopener noreferrer">
										<img src={APTNLogo} alt="APTN logo" className={HeaderStyles.aptnLogo} />
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</nav>
			<nav className={HeaderStyles.navDesktop}>
				<ul className={HeaderStyles.navItems}>
					<li>
						<a href="/">
							<img src={Logo} alt="" />
						</a>
					</li>
					<li>
						<Link to="/mayo-community" activeClassName="active">
							Mayo Community
							<span />
						</Link>
					</li>
					<li>
						<Link to="/preserving-our-culture" activeClassName="active">
							Preserving our Culture
							<span />
						</Link>
					</li>
					<li className={HeaderStyles.lastNavItem}>
						<Link to="/behind-the-scenes" activeClassName="active">
							Behind the Scenes
							<span />
						</Link>
					</li>
					<li>
						<ul className={HeaderStyles.social}>
							<li>
								<a
									href="https://www.facebook.com/Yukon-Harvest-580676159087393"
									target="_blank"
									rel="noopener noreferrer">
									<img src={Facebook} alt="Facebook logo" />
								</a>
							</li>
							<li>
								<a
									href="https://www.youtube.com/channel/UChE8q1NjaATWNCUOWBjCW0Q"
									target="_blank"
									rel="noopener noreferrer">
									<img src={YouTube} alt="YouTube logo" />
								</a>
							</li>
							<li>
								<a href="https://twitter.com/HarvestYukon" target="_blank" rel="noopener noreferrer">
									<img src={Twitter} alt="Twitter logo" />
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/yukonharvest/" target="_blank" rel="noopener noreferrer">
									<img src={Instagram} alt="Instagram logo" />
								</a>
							</li>
							<li>
								<a href="https://www.aptn.ca/yukonharvest" target="_blank" rel="noopener noreferrer">
									<img src={APTNLogo} alt="APTN logo" className={HeaderStyles.aptnLogo} />
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default Header;
