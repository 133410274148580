import React, { useState, useEffect } from 'react';
import '@styles/all.scss';

import { ContextProvider } from '@context/context';
import MetaData from '@molecules/MetaData/MetaData';
import Footer from '@molecules/Footer/Footer';

const TemplateWrapper = ({ children }) => {
	const [pageUrl, setPageUrl] = useState('');
	const [modalHidden, setModalHidden] = useState(true);
	const [videoSettings, setVideoSettings] = useState({
		playing: false,
		id: 0,
	});

	useEffect(() => {
		setPageUrl(window.location.href);
	}, [pageUrl]);

	const state = {
		modalHidden,
		setModalHidden,
		videoSettings,
		setVideoSettings,
	};
	return (
		<ContextProvider value={state}>
			<MetaData />
			{children}
			<Footer />
			<div className="overlay" aria-hidden={modalHidden} />
			<iframe
				width="0"
				height="0"
				title="cmfTagger"
				frameBorder="0"
				src={`/cmfTagger.html?applicationId=1718.112110.302744&medianame=Yukon Harvest DM&pageurl=${pageUrl}&sitename=Yukon Harvest DM`}
			/>
		</ContextProvider>
	);
};

export default TemplateWrapper;
