import React from 'react';
import PropTypes from 'prop-types';

import { Link as GatsbyLink } from 'gatsby';
import { parseClassNames } from '@utilities/parseClassNames';

import CtaStyles from './Cta.module.scss';

const Button = React.forwardRef(({ theme, onAction, className, children }, ref) => (
	<button
		type="button"
		onClick={onAction}
		ref={ref}
		className={parseClassNames([CtaStyles.cta, CtaStyles.cta_button, CtaStyles[`cta_theme_${theme}`], className])}>
		{children}
	</button>
));

const Link = React.forwardRef(({ href, theme, onAction, className, children }, ref) => {
	if (href.indexOf('http') > -1) {
		return (
			<a
				href={href}
				onClick={onAction}
				ref={ref}
				className={parseClassNames([CtaStyles.cta, CtaStyles.cta_anchor, CtaStyles[`cta_theme_${theme}`], className])}>
				{children}
			</a>
		);
	}
	return (
		<GatsbyLink
			to={href}
			onClick={onAction}
			ref={ref}
			className={parseClassNames([CtaStyles.cta, CtaStyles.cta_anchor, CtaStyles[`cta_theme_${theme}`], className])}>
			{children}
		</GatsbyLink>
	);
});

const Cta = React.forwardRef(({ url, theme, onAction, className, children }, ref) => {
	const classNames = parseClassNames(['cta', className]);
	if (url) {
		return (
			<Link href={url} theme={theme} onAction={onAction} className={classNames} ref={ref}>
				{children}
			</Link>
		);
	}
	return (
		<Button theme={theme} onAction={onAction} className={classNames} ref={ref}>
			{children}
		</Button>
	);
});

Cta.defaultProps = {
	url: '',
	theme: '',
	onAction: () => {},
	className: '',
};

Cta.propTypes = {
	url: PropTypes.string,
	theme: PropTypes.string,
	onAction: PropTypes.func,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default Cta;
