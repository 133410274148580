import React from 'react';
import PropTypes from 'prop-types';

import { ContextConsumer } from '@context/context';
import Cta from '@atoms/Cta/Cta';
import Icon from '@atoms/Icon/Icon';
import { parseClassNames } from '@utilities/parseClassNames';

import ModalStyles from './Modal.module.scss';

let modalState = {
	currentId: 0,
};

const toggleModal = (modalHidden, setModalHidden, children, setVideoSettings = () => {}, videoSettings = null) => {
	setModalHidden(!modalHidden);
	modalState = { ...modalState, ...{ currentId: children.key } };
	if (videoSettings) {
		setVideoSettings(prevState => ({ ...prevState, playing: false, id: 0 }));
	}
};

export const ModalLaunch = ({ children, className }) => {
	const classNames = parseClassNames([ModalStyles.modalLaunch, className]);
	return (
		<ContextConsumer>
			{({ modalHidden, setModalHidden }) => {
				return (
					<button
						type="button"
						className={classNames}
						onClick={() => toggleModal(modalHidden, setModalHidden, children)}>
						{children}
					</button>
				);
			}}
		</ContextConsumer>
	);
};

const Modal = ({ children, className }) => {
	const classNames = parseClassNames([ModalStyles.modalWrapper, className]);
	return (
		<ContextConsumer>
			{({ modalHidden, setModalHidden, videoSettings, setVideoSettings }) => {
				return (
					// eslint-disable-next-line eqeqeq
					<div className={classNames} aria-hidden={modalState.currentId != children.key}>
						<div className={ModalStyles.modal}>
							<Cta
								onAction={() => toggleModal(modalHidden, setModalHidden, { key: 0 }, setVideoSettings, videoSettings)}
								className={ModalStyles.modalClose}
								theme="dark">
								<Icon name="fa-times-circle" />
							</Cta>
							{children}
						</div>
					</div>
				);
			}}
		</ContextConsumer>
	);
};

Modal.defaultProps = {
	className: '',
};

Modal.propTypes = {
	children: PropTypes.shape({}).isRequired,
	className: PropTypes.string,
};

export default Modal;
