import React from 'react';

import CardsStyle from './Cards.module.scss';

const Cards = ({ children }) => {
	const styles = {
		card: CardsStyle.card,
		title: CardsStyle.title,
		image: CardsStyle.image,
		text: CardsStyle.text,
	};
	return <div className={CardsStyle.cards}>{children(styles)}</div>;
};

export default Cards;
