import React from 'react';

import { parseClassNames } from '@utilities/parseClassNames';

const Container = ({ children, className = '' }) => {
	const classNames = parseClassNames([className, 'container']);
	return <div className={classNames}>{children}</div>;
};

export default Container;
