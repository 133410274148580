import React from 'react';
import PropTypes from 'prop-types';
import { parseClassNames } from '../../utilities/parseClassNames';

const Heading = ({ className, headingLevel, size, children }) => {
	const validHeadings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
	const formatHeading = headingLevel.toLowerCase();
	const Title = validHeadings.includes(formatHeading) ? formatHeading : 'p';

	const classNames = parseClassNames([className, 'heading', `is-${size}`]);

	return <Title className={classNames}>{children}</Title>;
};

Heading.defaultProps = {
	className: '',
};

Heading.propTypes = {
	className: PropTypes.string,
	headingLevel: PropTypes.string.isRequired,
	size: PropTypes.string.isRequired,
};

export default Heading;
